import React from "react"
import { Row, Col } from "antd"
import {
  ContainerContent,
  Layout,
  SEO,
  ContainerContentFixed,
  BannerPage,
  Colors
} from "../components"

import { ContactInfo, ContactForm } from "../components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      Bg: file(relativePath: { eq: "7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Contact us"
        description={
          "For enquiries, questions and feedback, please contact us via the email/phone or the enquiry form"
        }
      />
      <BannerPage
        title={"Contact Us"}
        subTitle={
          "For enquiries, questions and feedback, please contact us via the email/phone below or via the enquiry form"
        }
      />

      <ContainerContent style={{ background: Colors.White }}>
        <ContainerContentFixed>
          <Row gutter={32}>
            <Col md={12}>
              <ContactInfo />
            </Col>
            <Col md={12}>
              <ContactForm />
            </Col>
          </Row>
        </ContainerContentFixed>
      </ContainerContent>
      <Img
        style={{ height: "400px" }}
        fluid={data["Bg"].childImageSharp.fluid}
      />
    </Layout>
  )
}
export default ContactPage
